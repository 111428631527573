<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<!-- 공급기업지정 프로젝트_전체프로젝트_프로젝트별 목록 -->
				<div class="list-box">
					<fixTop :inContent="true" fixClass="top-fix">
						<template v-slot:conts>
							<div class="bt-list mt-10">
								<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)">
							</div>

                            <div class="itv-result-box sp_human">
                                <div class="prj_ct">
                                    <div class="mark-box mt-2">
                                        <div v-if="parentProjectInfo.projectStatus == 2" class="mark ep">수행예정</div>
                                        <div v-if="parentProjectInfo.projectStatus == 4" class="mark bp">제안단계</div>
                                        <div v-if="parentProjectInfo.projectStatus == 9" class="mark cp">완료</div>
                                        <div v-if="parentProjectInfo.projectStatus == 1" class="mark pf">수행중</div>

                                        <div class="mark rc" v-if="parentProjectInfo.projectRecr > 0">+모 집 중</div>
                                        <!-- <div class="mark cp">모집완료</div> -->

                                        <div v-if="parentProjectInfo.contractYyyymmdd != undefined" class="orderDate">
                                            수주일자
                                            <span  style="margin-left: 10px;">{{parentProjectInfo.contractYyyymmdd | date('yyyy. MM. dd')}}</span>
                                        </div>
                                        <div class="regDate">등록일자<span style="margin-left: 10px;">{{parentProjectInfo.regDate | date('yyyy. MM. dd')}}</span></div>
                                    </div>
                                    <div class="prj_outline">
                                        <div class="name">
                                            <div class="label sco" style="vertical-align: top;">공급기업 지정</div>
                                            <div class="value name">{{parentProjectInfo.projectNm}}</div>
                                        </div>
                                        <div class="outline">
                                            <div class="label ivl03" style="margin-right: 22px;">개요</div>
                                            <div class="value multiText" style="line-height: 18px;">
                                                {{parentProjectInfo.projectDtlDesc}}
                                            </div>
                                        </div>
                                        <div class="date">
                                            <div class="label ivl01" style="margin-right: 60px;">개 발 기 간</div>
                                            <div class="value">{{parentProjectInfo.projectStartYyyymmdd | date('yyyy. MM. dd')}} ~ {{parentProjectInfo.projectEndYyyymmdd | date('yyyy. MM. dd')}}</div>
                                        </div>
                                        <div class="outline">
                                            <div class="label ivl02" style="margin-right: 60px;">고 객 사</div>
                                            <!-- <div class="value">{{parentProjectInfo.corpNm}}</div> -->
                                            <div class="value">{{parentProjectInfo.clientNm}}</div>
                                        </div>
                                        <div class="outline">
                                            <div class="label ivl02" style="margin-right: 60px;">수 행 사</div>
                                            <div class="value">{{parentProjectInfo.corpNm}}</div>
                                        </div>

                                        <div class="outline">
                                            <div class="label ivl02" style="margin-right: 60px;">담 당 자</div>
                                            <div class="value">{{parentProjectInfo.corpMgrMberNm}}</div><div v-if="!mberDiv" class="btn change" @click="clickChangeCorpMgrMberNm(parentProjectInfo.parentProjectSeq)">변경 <div class="triangle"></div></div>
                                        </div>

                                        <div class="outline">
                                            <div class="label ivl01" style="margin-right: 60px;">산 업 분 야</div>
                                            <div class="value"><SelectComp type="text" cdId="PRO105" :value="parentProjectInfo.indtypeClassCd.substr(0,1)"/> / <SelectComp type="text" cdId="PRO143" :value="parentProjectInfo.indtypeClassCd"/></div>
                                        </div>

                                        <div class="outline-btn">
                                            <!-- <div class="client_info">
                                                <div class="ci_box">
                                                    <ImageComp type="image" :src="'/api/prj/sco/choice/projectCorpImg?parentProjectSeq='+parentProjectInfo.parentProjectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
                                                </div>    
                                            </div> -->
                                            <!-- <div class="ci_box">
                                                    <ImageComp type="image" :src="'/api/prj/sco/choice/projectCorpImg?parentProjectSeq='+parentProjectInfo.parentProjectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
                                                </div>     -->
                                            <div class="ci_box" :class="mberDiv ? '' : 'ci_set'">
                                                <ImageComp type="image" :src="'/api/prj/sco/choice/projectCorpImg?parentProjectSeq='+parentProjectInfo.parentProjectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
                                            </div>
                                            <div class="btn mr-2-5">
                                                <img src="/images/tec/prj/list_view.png" alt="목록보기" @click="$router.go(-1);">
                                            </div>
                                            
                                            
                                            <div v-if="mberDiv" class="btn">
                                                <img src="/images/prj_change.png" alt="프로젝트 수정하기" @click="goModifyProject()">
                                            </div>
                                        </div>
                                    </div>                                
                                </div>

                                <div class="Board type3 mt-0">
                                    <table class="Board_type3 prj list-all mx-0" style="border-top: none; border-bottom: none;">
                                        <colgroup>
                                            <col width="3%">
                                            <col width="*">
                                            <col width="10%">
                                            <col width="12%">
                                            <col width="8%">
                                            <col width="20%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>공급기업명</th>
                                                <th>등록유무</th>
                                                <th>등록일</th>
                                                <th>상태</th>
                                                <th>프로젝트 보기</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(scoPrj, idx) in parentProjectInfo.scoPrjList" :key="scoPrj.corpSeq">
                                                <td class="score">{{idx + 1}}</td>
                                                <td class="score">
                                                    <div class="rc_tag">
                                                        <div v-if="scoPrj.projectSeq == undefined" class="rc_mark_red">미등록</div>
                                                        <div v-else-if="scoPrj.recrEndDateDiff >= 0" class="rc_mark">+모집중</div>
                                                    </div>
                                                    {{scoPrj.corpNm}}
                                                </td>

                                                <td v-if="scoPrj.projectSeq != undefined" class="score">등록완료</td>
                                                <td v-else class="score">-</td>

                                                <td v-if="scoPrj.projectSeq != undefined" class="score">{{scoPrj.regDate | date('yyyy. MM. dd')}}</td>
                                                <td v-else class="score">-</td>

                                                <td v-if="scoPrj.projectSeq != undefined && scoPrj.recrEndDateDiff >= 0" class="score">모집중</td>
                                                <td v-else-if="scoPrj.projectSeq != undefined && parentProjectInfo.projectStatus == 2" class="score">수행예정</td>
                                                <td v-else-if="scoPrj.projectSeq != undefined && parentProjectInfo.projectStatus == 4" class="score">제안단계</td>
                                                <td v-else-if="scoPrj.projectSeq != undefined && parentProjectInfo.projectStatus == 9" class="score">완료</td>
                                                <td v-else-if="scoPrj.projectSeq != undefined && parentProjectInfo.projectStatus == 1" class="score">수행중</td>
                                                <td v-else class="score">-</td>

                                                <td v-if="scoPrj.projectSeq != undefined" class="score">
                                                    <div class="btn" style="min-width: 110px;" @click="mberDiv ? goProject(scoPrj) : goGmgProject(scoPrj)">프로젝트 보기 &#9654;</div>
                                                </td>
                                                <td v-else class="score">-</td>
                                            </tr>
                                            <!-- <tr>
                                                <td class="score">1</td>
                                                <td class="score">
                                                    <div class="rc_tag">
                                                        <div class="rc_mark">+모집중</div>
                                                    </div>
                                                    (주)경동나비엔
                                                </td>
                                                <td class="score">-</td>
                                                <td class="score">-</td>
                                                <td class="score">-</td>
                                                <td class="score">-</td>
                                            </tr>
                                            <tr>
                                                <td class="score">1</td>
                                                <td class="score">
                                                    <div class="rc_tag">
                                                        <div class="rc_mark_red">미승인</div>
                                                    </div>
                                                    (주)다모여인력
                                                </td>
                                                <td class="score">등록완료</td>
                                                <td class="score">2024.00.00</td>
                                                <td class="score">모집중</td>
                                                <td class="score">
                                                    <div class="btn" style="min-width: 110px;">프로젝트 보기 &#9654;</div>
                                                </td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>

									
							</div>
                            							
							
							<!-- 페이징부분 -->
							<!-- <pagingComp :pageInfo="pageInfo" @page-click="goPage" /> -->
						</template>
						
					</fixTop>
				</div>

			</div>
		</div>
	</div>
</template>

<script>


import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			// pageInfo : {},
			
            parentProjectInfo : {},

			nowYyyymmdd : new Date().format("yyyyMMdd"),
		}
	},

	components : {
		 fixTop
	},

	beforeMount() {
		// console.log('this param', this.$route.params.srchName);
		// if(this.$route.params.srchName != '') {
		// 	this.srcFilter.srcTxt = this.$route.params.srchName;
		// }
		// this.parentProjectInfo = this.$route.params.project;
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
        // console.log(this.$route);
		this.getParentDetail();
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		getParentDetail() {
            this.$.httpPost('/api/prj/sco/choice/getParentDetail', {parentProjectSeq : this.$route.query.projectSeq})
                    .then((res) => {
                        // console.log('getParentDetail RESULT', res.data);
                        this.parentProjectInfo = res.data;
                    });		
		},

        goModifyProject() {
			this.$router.push({name : 'PRJ303M02', query : {projectSeq : this.parentProjectInfo.parentProjectSeq}, params : {caller : { name : this.$route.name, params :this.input}}});
		},

        goProject(scoPrj) {
            // console.log(scoPrj);
            // console.log(this.parentProjectInfo.projectNm);
            if(scoPrj.recrEndDateDiff >= 0) {
                this.$router.push({name : 'PRJ307M01', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}});
            } else {
                switch(this.parentProjectInfo.projectStatus) {
                    case '4' : this.$router.push({name : 'PRJ307M02', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '2' : this.$router.push({name : 'PRJ307M03', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '1' : this.$router.push({name : 'PRJ307M04', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '9' : this.$router.push({name : 'PRJ307M05', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                        
                }
            }
                

        },
        goGmgProject(scoPrj) {
            // console.log(scoPrj);
            // console.log(this.parentProjectInfo.projectNm);
            if(scoPrj.recrEndDateDiff >= 0) {
                this.$router.push({name : 'PRJ203M01', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}});
            } else {
                switch(this.parentProjectInfo.projectStatus) {
                    case '4' : this.$router.push({name : 'PRJ203M02', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '2' : this.$router.push({name : 'PRJ203M03', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '1' : this.$router.push({name : 'PRJ203M04', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                    case '9' : this.$router.push({name : 'PRJ203M05', params : { srchName : this.parentProjectInfo.projectNm,corpName : scoPrj.corpNm, caller : { name : this.$route.name, params :this.input}}}); break;
                        
                }
            }
                

        },

        clickChangeCorpMgrMberNm(projectSeq) {
			var param = {
				projectSeq : projectSeq,
				parentProjectYn : 'Y',
			};
			this.$.popup('/dco/gmg/prj/PRJ201P01', param, {width:0, height:0})
				.then(() => {
					this.getParentDetail();
				})
				// .catch(err => {})
				;
		},
	},
    computed : {
		mberDiv() {
			// 수요 Y 관리총괄 N
			if(this.$store.state.userInfo.mberDivCd == '22') return true
			return false;
		}
	}
}
</script>